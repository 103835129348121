import { Avatar, Box, IconButton, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import {
  formatDateTime,
  unixToDateTimeWithFormat,
} from "../../../../utils/date-converter";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedNumberTextField from "../../../Custom/CustomizedNumberTextField";
import CustomizedLetterAvatar from "../../../Custom/CustomizedLetterAvatar";
import CustomizedComboBox from "../../../Custom/CustomizedComboBox";
import { formatNumber } from "../../../../utils/dataTransformer";

const TraceEntryList = ({
  nestIndex,
  control,
  disabled,
  isLoading,
  errors,
  setValue,
  errorIndexList,
  setErrorIndexList,
}) => {
  const { fields, remove } = useFieldArray({
    control,
    name: `goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list`,
    keyName: "genId",
  });
  const watchType = useWatch({ control, name: "type" });
  const watchTotalItemFromDocument = useWatch({
    control,
    name: `goods_issue_list[${nestIndex}].initial_quantity`,
  });
  const [totalError, setTotalError] = useState(false);

  const watchTotalItemAlreadyQty =
    useWatch({
      control,
      name: `goods_issue_list[${nestIndex}].already_posted_quantity`,
    }) || 0;

  const watchQuantity = useWatch({
    control,
    name: `goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list`,
  });

  const totalQuantity =
    watchQuantity?.reduce(
      (prev, curr) => prev + parseFloat(curr?.posted_quantity),
      0
    ) || 0;

  const watchTraceEntry = useWatch({
    control,
    name: `goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list`,
  });
  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  useEffect(() => {
    if (watchType !== "สินค้าเสีย" && watchType !== "อื่นๆ") {
      if (
        totalQuantity >
        watchTotalItemFromDocument - watchTotalItemAlreadyQty
      ) {
        setTotalError(true);
        setErrorIndexList((prev) => {
          const updatedList = Array.isArray(prev) ? [...prev] : [];
          // Ensure the array has enough length to reach the nestIndex
          if (updatedList.length <= nestIndex) {
            // fill in if array previous doesn't have any value
            while (updatedList.length <= nestIndex) {
              updatedList.push(false);
            }
          }
          updatedList[nestIndex] = true;

          return updatedList;
        });
      } else {
        setTotalError(false);
        setErrorIndexList((prev) => {
        const updatedList = Array.isArray(prev) ? [...prev] : [];
          // Ensure the array has enough length to reach the nestIndex
          if (updatedList.length <= nestIndex) {
            // fill in if array previous doesn't have any value
            while (updatedList.length <= nestIndex) {
              updatedList.push(false);
            }
          }
          updatedList[nestIndex] = false;

          return updatedList;
        });
      }
    }
  }, [
    setTotalError,
    totalQuantity,
    watchTotalItemAlreadyQty,
    watchTotalItemFromDocument,
    watchType,
    setErrorIndexList,
    nestIndex,
  ]);

  return (
    <>
      {fields.map((trace, index) => (
        <TableRow key={trace.genId}>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.posted_date
                ? typeof trace.posted_date === "string"
                  ? formatDateTime(trace.posted_date)
                  : unixToDateTimeWithFormat(trace.posted_date)
                : null
            )}
          </TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center"></TableCell>
          <TableCell align="center">
            {disabled ? (
              formatNumber(Math.abs(trace.posted_quantity))
            ) : watchType === "ผลิตวัตถุดิบ" ||
              watchType === "ผลิตแปรรูป" ||
              watchType === "ผลิตแปรรูป - PT" ||
              watchType === "ส่งคืน" ||
              watchType === "สินค้าเสีย" ||
              watchType === "อื่นๆ" ? (
              <Box width={125}>
                <CustomizedNumberTextField
                  control={control}
                  name={`goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`}
                  disabled={isLoading}
                  type="number"
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  error={
                    Boolean(
                      errors &&
                        errors?.goods_issue_list &&
                        errors?.goods_issue_list[nestIndex]?.stock_entry_list
                          ?.trace_entry_list[index]?.posted_quantity
                    ) || false
                  }
                  helperText={
                    // (errors &&
                    //   errors?.goods_issue_list &&
                    //   errors?.goods_issue_list[nestIndex]?.stock_entry_list
                    //     ?.trace_entry_list[index]?.posted_quantity
                    //     .message) ??
                    "จำนวนคงคลัง" +
                    " " +
                    watchTraceEntry[index]?.current_quantity
                  }
                />
              </Box>
            ) : (
              <Box width={125}>
                <CustomizedNumberTextField
                  control={control}
                  name={`goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].posted_quantity`}
                  disabled={isLoading}
                  error={
                    errors &&
                    errors?.goods_issue_list &&
                    errors?.goods_issue_list[nestIndex]?.stock_entry_list
                      ?.trace_entry_list[index]?.posted_quantity
                      ? Boolean(
                          errors?.goods_issue_list[nestIndex]?.stock_entry_list
                            ?.trace_entry_list[index]?.posted_quantity
                        )
                      : totalError
                  }
                  type="number"
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  helperText={
                    errors
                      ? errors?.goods_issue_list &&
                        errors?.goods_issue_list[nestIndex]?.stock_entry_list
                          ?.trace_entry_list[index]?.posted_quantity
                        ? errors?.goods_issue_list[nestIndex]?.stock_entry_list
                            ?.trace_entry_list[index]?.posted_quantity.message
                        : totalError
                        ? `กรุณาระบุจำนวนนำออกไม่เกินจำนวนจากเอกสาร`
                        : ""
                      : "จำนวนคงคลัง" +
                        " " +
                        formatNumber(
                          watchTraceEntry[index]?.current_quantity ?? 0
                        )
                  }
                />
              </Box>
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.uom.name)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(trace.serial_number)}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              disabled || trace.location_list.length === 1 ? (
                trace.source_bin_location_document_id
              ) : (
                <Box width={220}>
                  <Controller
                    key="gt-source_bin_location_document_id-field"
                    name={`goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].source_bin_location_document_id`}
                    control={control}
                    render={({ field }) => (
                      <CustomizedComboBox
                        {...field}
                        options={trace.location_list || []}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.value : "");
                          setValue(
                            `goods_issue_list[${nestIndex}].stock_entry_list.trace_entry_list[${index}].current_quantity`,
                            newValue.current_quantity
                          );
                        }}
                        error={
                          errors &&
                          errors?.goods_issue_list &&
                          errors?.goods_issue_list[nestIndex]?.stock_entry_list
                            ?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          Boolean(
                            errors?.goods_issue_list[nestIndex]
                              ?.stock_entry_list?.trace_entry_list[index]
                              ?.source_bin_location_document_id
                          )
                        }
                        helperText={
                          errors &&
                          errors?.goods_issue_list &&
                          errors?.goods_issue_list[nestIndex]?.stock_entry_list
                            ?.trace_entry_list[index]
                            ?.source_bin_location_document_id &&
                          errors?.goods_issue_list[nestIndex]?.stock_entry_list
                            ?.trace_entry_list[index]
                            ?.source_bin_location_document_id.message
                        }
                        // title={t("sales.paymentReceipt.eWalletType")}
                        // readOnly={viewOnly}
                        required
                      />
                    )}
                  />
                </Box>
              )
            )}
          </TableCell>
          <TableCell align="center">
            {renderBoxMaxContent(
              trace.created_by?.img_url ? (
                <Avatar alt="img_url" src={trace.created_by?.img_url} />
              ) : (
                <CustomizedLetterAvatar
                  name={
                    trace.created_by?.first_name +
                    " " +
                    trace.created_by?.last_name
                  }
                />
              )
            )}
          </TableCell>

          <TableCell align="center">
            {renderBoxMaxContent(trace.batch_number)}
          </TableCell>
          <TableCell align="center">
            {disabled ? null : (
              <IconButton onClick={() => remove(index)} disabled={false}>
                <CloseIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TraceEntryList;
