import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment } from "react";
import { useWatch } from "react-hook-form";
import TraceEntryList from "./TraceEntryList";
import CloseIcon from "@mui/icons-material/Close";
import { formatNumber } from "../../../../utils/dataTransformer";

const GoodsIssueTable = ({
  control,
  fields,
  remove,
  disabled,
  isLoading,
  setValue,
  getValues,
  errorIndexList,
  setErrorIndexList,
  errors,
}) => {
  const watchType = useWatch({ control, name: "type" });

  const TotalPostedQuantity = ({ control, index }) => {
    const watchQuantity = useWatch({
      control,
      name: `goods_issue_list[${index}].stock_entry_list.trace_entry_list`,
    });
    const totalQuantity =
      watchQuantity?.reduce(
        (prev, curr) => prev + parseFloat(curr?.posted_quantity),
        0
      ) || 0;
    return (
      <TableCell align="center">
        {formatNumber(Math.abs(totalQuantity))}
      </TableCell>
    );
  };

  const header = [
    "ลำดับ",
    "วันที่และเวลา",
    "รหัสสินค้า - ชื่อสินค้า",
    "จำนวนจากเอกสาร",
    "จำนวนนำออกแล้ว",
    "จำนวนรอนำออก",
    "จำนวนที่จะนำออก",
    "หน่วย",
    "SN",
    "สถานที่",
    "ผู้สแกน",
    "Barcode",
    "",
  ];

  const renderBoxMaxContent = (content) => (
    <Box sx={{ width: "max-content" }}>{content}</Box>
  );

  return (
    <TableContainer>
      <Table
        sx={{ minWidth: 650, width: "100%", overflow: "scroll" }}
        aria-label="simple table"
      >
        <TableHead style={{ backgroundColor: "#E8F1E8" }}>
          <TableRow>
            {header.map((header, index) => (
              <TableCell key={index}>
                <Box width="max-content">{header}</Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, index) => (
            <Fragment key={index}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {renderBoxMaxContent(
                    row.stock_entry_list?.item?.document_id +
                      " - " +
                      row.stock_entry_list?.item?.name
                  )}
                </TableCell>
                <TableCell align="center">
                  {renderBoxMaxContent(
                    watchType === "อื่นๆ"
                      ? "-"
                      : formatNumber(row.initial_quantity ?? 0)
                  )}
                </TableCell>
                <TableCell align="center">
                  {renderBoxMaxContent(
                    watchType === "อื่นๆ"
                      ? "-"
                      : formatNumber(row.already_posted_quantity ?? 0)
                  )}
                </TableCell>
                <TableCell align="center">
                  {renderBoxMaxContent(
                    watchType === "อื่นๆ"
                      ? "-"
                      : formatNumber(
                          row.initial_quantity - row.already_posted_quantity
                        )
                  )}
                </TableCell>
                <TotalPostedQuantity control={control} index={index} />
                <TableCell align="center">
                  {renderBoxMaxContent(row.stock_entry_list?.uom?.name)}
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  {disabled || watchType !== "อื่นๆ" ? null : (
                    <Box display={"flex"} justifyContent={"end"}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={false}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
              <TraceEntryList
                control={control}
                nestIndex={index}
                disabled={disabled}
                isLoading={isLoading}
                setValue={setValue}
                getValues={getValues}
                errorIndexList={errorIndexList}
                setErrorIndexList={setErrorIndexList}
                errors={errors}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GoodsIssueTable;
