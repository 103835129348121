import { Grid, IconButton, Typography, Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomizedBox } from "../../../components/Custom/CustomizedBox";
import CustomizedTextField from "../../../components/Custom/CustomizedTextField";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CustomizedSelect from "../../../components/Custom/CustomizedSelect";
import CustomizedComboBox from "../../../components/Custom/CustomizedComboBox";
import SalesService from "../../../services/Sales";
import { dateToUnix } from "../../../utils/date-converter";
import { useAuth } from "../../../hooks/use-auth";
import InventoryService from "../../../services/Inventory";
import PurchaseService from "../../../services/Purchase";
import { CustomizedTooltip } from "../../../components/Custom/CustomizedTooltip";
import ManufactureService from "../../../services/Manufacture";
import { useParams } from "react-router-dom";

const defaultOptions = {
  data: [],
  type: "",
};

const IssueDetail = ({
  isCreate,
  control,
  setValue,
  getValues,
  errors,
  disabled,
  isLoading,
  watchWarehouse,
  generateDocumentId,
  replace,
  reset,
  resetFieldBarcode,
  setIsLoadingRefDocument,
}) => {
  const [referenceDocumentOptions, setReferenceDocumentOptions] =
    useState(defaultOptions);
  const { t } = useTranslation();
  const { allLocation } = useSelector((state) => state.location);
  const { user } = useAuth();
  const { id } = useParams();

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const warehouseOption = allLocation?.map((location) => {
    return {
      id: location.document_id,
      label: location.thai_name,
      value: location.document_id,
    };
  });

  const watchType = useWatch({ control, name: "type" });
  const watchRefDoc = useWatch({ control, name: "reference_document_id" });

  const typeOption = [
    { label: "ขาย", value: "ขาย" }, //SO
    { label: "ขายออนไลน์", value: "ขายออนไลน์" }, //SO
    { label: "ผลิตวัตถุดิบ", value: "ผลิตวัตถุดิบ" }, //MO
    { label: "ผลิตแปรรูป", value: "ผลิตแปรรูป" }, //MO
    { label: "ผลิตแปรรูป - PT", value: "ผลิตแปรรูป - PT" }, //PT -> MO
    { label: "ส่งคืน", value: "ส่งคืน" }, //SR
    { label: "สินค้าเสีย", value: "สินค้าเสีย" },
    { label: "อื่นๆ", value: "อื่นๆ" },
  ];

  const currentWarehouse = useWatch({
    control,
    name: "source_warehouse_document_id",
  });

  const mapWarehouseIdToName = () => {
    const findId = allLocation?.find(
      (location) => location.document_id === currentWarehouse
    );
    if (findId) return findId.thai_name;
    else return "";
  };

  const fetchDocument = useCallback(async () => {
    setReferenceDocumentOptions({ ...defaultOptions, data: [] });
    setIsLoadingOptions(true);
    let data = null;
    const type = getValues("type");
    if (["ขาย", "ขายออนไลน์"].includes(type)) {
      const input = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          render_status: {
            values: ["approved", "finished"],
            filterType: "set",
          },
        },
      };
      const { results } = await SalesService.getAllSalesOrderIds(input);
      data = await results;
      setIsLoadingOptions(false);
    } else if (
      ["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"].includes(type)
    ) {
      const input = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          render_status: {
            values: ["finished", "inProgress", "pendingManu"],
            filterType: "set",
          },
        },
      };
      const { results } = await ManufactureService.getAllManufactureOrderIds(
        input
      );
      data = await results;
      setIsLoadingOptions(false);
    } else if (["ส่งคืน"].includes(type)) {
      const input = {
        startRow: 0,
        endRow: Math.pow(10, 10),
        filterModel: {
          render_status: {
            values: ["approved", "finished"],
            filterType: "set",
          },
        },
      };
      const { results } = await PurchaseService.getAllPurchaseReturnIds(input);
      data = await results;
      setIsLoadingOptions(false);
    }

    setReferenceDocumentOptions({
      data: data || [],
      type: type,
    });
  }, [getValues]);

  useEffect(() => {
    if (
      !id &&
      ((["ขาย", "ขายออนไลน์"].includes(watchType) &&
        !["ขาย", "ขายออนไลน์"].includes(referenceDocumentOptions.type)) ||
        (["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"].includes(
          watchType
        ) &&
          !["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"].includes(
            referenceDocumentOptions.type
          )) ||
        (["ส่งคืน"].includes(watchType) &&
          !["ส่งคืน"].includes(referenceDocumentOptions.type)))
    )
      fetchDocument();
  }, [watchType, referenceDocumentOptions.type, fetchDocument, id]);

  const resetValue = useCallback(
    (onlyList) => {
      if (isCreate) {
        if (!onlyList) setValue("reference_document_id", "");
        setValue("goods_issue_list", []);
      }
    },
    [isCreate, setValue]
  );

  const checkTypeIfSameDoc = useCallback(async () => {
    const type = getValues("type");
    const refDoc = getValues("reference_document_id");
    if (
      refDoc.slice(0, 2) === "SO" &&
      type !== "ขาย" &&
      type !== "ขายออนไลน์"
    ) {
      resetValue();
    } else if (
      refDoc.slice(0, 2) === "MO" &&
      type !== "ผลิตวัตถุดิบ" &&
      type !== "ผลิตแปรรูป" &&
      type !== "ผลิตแปรรูป - PT"
    ) {
      resetValue();
    } else if (refDoc.slice(0, 2) === "SR" && type !== "ส่งคืน") {
      resetValue();
    } else if (type === "สินค้าเสีย" || type === "อื่นๆ") {
      resetValue();
    } else if ((type !== "สินค้าเสีย" || type !== "อื่นๆ") && refDoc === "")
      resetValue(true);
  }, [getValues, resetValue]);

  const getTeList = useCallback(
    async (allItems) => {
      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          inventory_object_item: {
            filterType: "object",
            filter: {
              goods_issue: {
                filterType: "object",
                filter: {
                  reference_document_id: {
                    filterType: "text",
                    filter: watchRefDoc,
                    type: "contains",
                  },
                },
              },
            },
          },
          is_active: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        },
        sortModel: [],
      };
      if (watchRefDoc) {
        const allItems = getValues("goods_issue_list");
        const { results } = await InventoryService.getAllSEAggrid(input);
        // console.log("results", results);
        const newItemWithCalData = [];
        allItems.forEach((item) => {
          const newItems = results.filter(
            (result) =>
              result.trace_entry_list[0]?.item_uid ===
                item.stock_entry_list.trace_entry_list[0]?.item_uid ||
              result.trace_entry_list[0]?.item_uid ===
                item.stock_entry_list?.item_uid
          );
          return newItemWithCalData.push({
            ...item,
            already_posted_quantity: newItems.reduce(
              (prev, curr) => prev + Math.abs(curr.posted_quantity),
              0
            ),
          });
        });
        if (id) setValue("goods_issue_list", newItemWithCalData);
      }
    },
    [watchRefDoc, getValues, id, setValue]
  );

  useEffect(() => {
    getTeList();
  }, [getTeList]);

  const selectedDocumentHandler = async (field, newValue) => {
    let findItemFromRef = undefined;
    const currValue = getValues();
    resetFieldBarcode("barcode");
    // reset to initial form (before mutate goods_issue_list)
    if (newValue && newValue.value) {
      if (currValue.reference_document_id !== newValue.value) {
        reset({
          ...currValue,
          reference_document_id: newValue.value,
          goods_issue_list: [],
        });
      }

      field.onChange(newValue.value);

      let input = {
        startRow: 0,
        endRow: 999999,
        filterModel: {
          inventory_object_item: {
            filterType: "object",
            filter: {
              goods_issue: {
                filterType: "object",
                filter: {
                  reference_document_id: {
                    filterType: "text",
                    filter: newValue.value,
                    type: "contains",
                  },
                },
              },
            },
          },
          is_active: {
            filterType: "boolean",
            type: "equals",
            filter: "true",
          },
        },
        sortModel: [],
      };

      if (["ขาย", "ขายออนไลน์"].includes(currValue.type)) {
        const salesOrder = await SalesService.getSalesOrderById({
          document_id: newValue.value,
        });
        findItemFromRef = {
          ...salesOrder,
          item_list: salesOrder.item_list.map((item) => {
            return {
              ...item,
              document_id: item.item_document_id,
              name: item.item_name,
              description: item.item_description,
              quantity: item.qty,
              uom: item.uom,
            };
          }),
        };
      }
      if (
        ["ผลิตวัตถุดิบ", "ผลิตแปรรูป", "ผลิตแปรรูป - PT"].includes(
          currValue.type
        )
      ) {
        const manufactureOrder =
          await ManufactureService.getManufactureOrderById(newValue.value);
        findItemFromRef = {
          ...manufactureOrder,
          item_list: manufactureOrder.ingredient_list.map((item) => {
            return {
              ...item,
              document_id: item.item_document_id,
              name: item.item_name,
              description: item.item_description,
              quantity: item.qty,
              uom: { document_id: item.uom, name: item.uom },
            };
          }),
        };
      }
      if (["ส่งคืน"].includes(currValue.type)) {
        const purchaseReturn = await PurchaseService.getPurchaseReturnById({
          document_id: newValue.value,
        });
        findItemFromRef = await {
          ...purchaseReturn,
          item_list: purchaseReturn.item_list.map((item) => {
            return {
              ...item,
              document_id: item.item_document_id,
              name: item.item_name,
              description: item.item_description,
              quantity: item.qty,
              uom: item.uom,
            };
          }),
        };
      }
      if (findItemFromRef) {
        const { results } = await InventoryService.getAllSEAggrid(input);
        const formatValue = findItemFromRef.item_list.map((item) => {
          const newItems = results.filter(
            (result) => result.trace_entry_list[0]?.item_uid === item?.uid
          );

          return {
            initial_quantity: item.quantity,
            posted_quantity: item.quantity,
            posted_value: 0,
            already_posted_quantity: newItems.reduce(
              (prev, curr) => prev + Math.abs(curr.posted_quantity),
              0
            ),
            stock_entry_list: {
              destination_warehouse: "",
              entry_type: "",
              is_active: true,
              item: item,
              posted_date: dateToUnix(new Date()),
              posted_quantity: item.quantity,
              posted_value: 0,
              reference_document_id: "",
              reference_document_type: "issue",
              source_warehouse_document_id: watchWarehouse,
              trace_entry_list: [],
              created_by: user,
              uom: item.uom,
              item_uid: item.uid,
            },
          };
        });
        replace(formatValue);
      }
    } else {
      reset({
        ...currValue,
        reference_document_id: "",
        goods_issue_list: [],
      });
      field.onChange("");
    }
    setIsLoadingRefDocument(false);
  };

  return (
    <CustomizedBox margin={0}>
      <Typography sx={{ fontWeight: 700, mb: 3 }}>
        {t("description")}
      </Typography>
      <Grid container spacing={2} mb={2}>
        <Grid
          item
          xs={disabled ? 12 : 10}
          sm={disabled ? 12 : 10}
          md={6}
          lg={4}
          xl={4}
          display="flex"
          justifyContent="center"
        >
          <Controller
            control={control}
            name="document_id"
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.issue.documentId")}
                error={Boolean(errors.document_id)}
                helperText={errors.document_id?.message}
                required
                disabled={!isCreate || isLoading}
              />
            )}
          />
        </Grid>
        {disabled ? null : (
          <Grid item xs={2}>
            <Box sx={{ ml: -1 }}>
              <CustomizedTooltip title="เรียกเลขที่เอกสารใหม่">
                <IconButton
                  onClick={() => generateDocumentId()}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <RestartAltOutlinedIcon />
                </IconButton>
              </CustomizedTooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <CustomizedSelect
                {...field}
                options={typeOption}
                label={t("inventory.issue.issueType")}
                onChange={(e) => {
                  field.onChange(e);
                  checkTypeIfSameDoc(e.target.value);
                }}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          {watchType === "อื่นๆ" || watchType === "สินค้าเสีย" ? (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedTextField
                  {...field}
                  label={t("inventory.referenceNo")}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  noValidate
                  disabled={true}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={"reference_document_id"}
              render={({ field }) => (
                <CustomizedComboBox
                  {...field}
                  loading={isLoadingOptions}
                  error={Boolean(errors.reference_document_id)}
                  helperText={errors.reference_document_id?.message}
                  options={referenceDocumentOptions?.data?.map((doc) => {
                    return {
                      id: doc.id,
                      value: doc.document_id,
                      label: doc.document_id,
                    };
                  })}
                  label={t("inventory.referenceNo")}
                  onChange={(_, newValue) => {
                    setIsLoadingRefDocument(true);
                    selectedDocumentHandler(field, newValue);
                  }}
                  disabled={!isCreate || isLoading}
                  required
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"source_warehouse_document_id"}
            render={({ field }) => (
              <CustomizedComboBox
                options={warehouseOption}
                label={t("inventory.warehouse")}
                onChange={(_, newValue) =>
                  field.onChange(newValue ? newValue.value : "")
                }
                error={Boolean(errors.source_warehouse_document_id)}
                helperText={errors.source_warehouse_document_id?.message}
                value={mapWarehouseIdToName()}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <Controller
            control={control}
            name={"remark"}
            render={({ field }) => (
              <CustomizedTextField
                {...field}
                label={t("inventory.remark")}
                error={Boolean(errors.remark)}
                helperText={errors.remark?.message}
                disabled={!isCreate || isLoading}
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <CustomizedTextField
            label={t("inventory.remarkStaff")}
            value={item.remark_staff || ""}
            disabled={!isCreate}
          />
        </Grid> */}
      </Grid>
    </CustomizedBox>
  );
};

export default IssueDetail;
